import React from 'react';
import { CDBModalFooter, CDBBox, CDBBtn, CDBIcon } from 'cdbreact';

export default function Footer () {
	const links = {
		github: "https://github.com/NeleB54Gold",
		telegram: "https://t.me/NeleBots"
	};
	
  return (
	<CDBModalFooter>
	  <CDBBox
		display="flex"
		justifyContent="between"
		alignItems="center"
		className="mx-auto py-4 flex-wrap"
		style={{ width: '95%' }}
	  >
		<CDBBox display="flex" alignItems="center">
		  <small className="ms-2">&copy; NeleBots Productions, 2023. All rights reserved.</small>
		</CDBBox>
		<CDBBox display="flex">
		  <CDBBtn flat className="-2" id="btn" href={links.telegram} target="_blank">
			<CDBIcon fab icon="telegram" />
		  </CDBBtn>
		  <CDBBtn flat className="mx-3 p-2" id="btn" href={links.github} target="_blank">
			<CDBIcon fab icon="github" />
		  </CDBBtn>
		</CDBBox>
	  </CDBBox>
	</CDBModalFooter>
  );
};