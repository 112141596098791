import React from 'react';
import './home.css';
import logo from '../logo.svg';
import { useTranslation } from "react-i18next";
import ShowProjects from '../myProjects';
import Footer from '../footer';

const Home = () => {
	const { t } = useTranslation();
	
	return (
		<div id='home'>
			<header className="header">
				<p>
					<img src={logo} className="logo" alt="Logo" />
					<h2>Welcome in the <b>NeleBots Productions</b>!</h2>
					<b>Be</b> the change <b>you want</b> to see in the world.
				</p>
			</header>
			<hr/>
			<p className="myProjects">
				<h1>{t('myProjects')}</h1>
				<ShowProjects />
			</p>
			<div className="footerSpace">
				<Footer />
			</div>
		</div>
	);
}

export default Home;