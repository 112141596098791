import React from 'react';
import Bots from './data/bots.json';
import Popup from 'reactjs-popup';
import { CDBIcon } from 'cdbreact';
import { useTranslation } from "react-i18next";

function getPopUp (Bot) {
	const { t } = useTranslation();
	
	var photo = "assets/bots/" + (Bots[Bot]['photo']);
	var link = "https://t.me/" + Bots[Bot]['clones'][0]['username'];
	if (Bots[Bot]['news']) {
		var news_link = "https://t.me/" + Bots[Bot]['news']['username'];
		var news_username = "@" + Bots[Bot]['news']['username'];
		var news = <p>
			<h4><CDBIcon fa icon="bullhorn"/> {t('botUpdates')}</h4>
			{t('botUpdatesDesc')} <a href={news_link}>{news_username}</a>
		</p>;
	}
	if (Bots[Bot]['translations']) {
		if (Bots[Bot]['translations']['local_id']) {
			var translation_link = "https://translations.nelebots.com/r/" + Bots[Bot]['translations']['local_id'];
		} else if (Bots[Bot]['translations']['bot']) {
			translation_link = "https://t.me/" + Bots[Bot]['translations']['bot'];
		} else {
			translation_link = "https://nelebots.oneskyapp.com/collaboration/translate/project/project/" + Bots[Bot]['translations']['platform_id'] + "/";
		}
		var translations = <p>
			<h4><CDBIcon fa icon="language"/> {t('botTranslations')}</h4>
			{t('botTranslationsDesc')} <a href={translation_link}>{t('botTranslateNow')}</a>
		</p>;
	}
	if (Bots[Bot]['github']) {
		var github_link = "https://github.com/" + Bots[Bot]['github'];
		var github = <p>
			<h4><CDBIcon fab icon="github"/> {t('botOpenSource')}</h4>
			{t('botOpenSourceDesc')} <a href={github_link}>{Bots[Bot]['github']}</a>
		</p>;
	}
	if (Bots[Bot]['clones'][0]['id']) {
		var stats_link = "/stats?bot=" + encodeURIComponent(Bot);
		var graphs = <div className="graph">
			<h4><CDBIcon fa icon="line-chart"/> {t('botStats')}</h4>
			{t('botStatsDesc')} <a href={stats_link}>{t('botStatsPage')}</a>!
		</div>;
	}
	
	return close => (<div className="BotInfoCotainer">
		<div className="BotInfo">
			<div className="BotInfoHeader">
			<img src={photo} alt={t('botLogo')} loading="lazy" />
				<div align="left">
					<h2>{Bot}</h2>
					<p>
						{Bots[Bot]['description']}
						<br/>
					</p>
				</div>
			</div>
			<div align="left">
				{news}
				{github}
				{translations}
				{graphs}
			</div>
			<div className="close" onClick={close}>
				<button type="button" className="btn button btn-primary btn-lg btn-block">{t('close')}</button>
				&nbsp;
				<a href={link}><button type="button" className="btn button btn-primary btn-lg btn-block">{t('tryOnTelegram')}</button></a>
			</div>
		</div>
	</div>);
}

function ShowBotList() {
	const showTheseBots = [];
	const { t } = useTranslation();
	
	Object.keys(Bots).map(function callback(Bot, key) {
		var ClosedEmoji, bottomText = '';
		var photo = "assets/bots/" + (Bots[Bot]['photo']);
		if (Bots[Bot]['closed_for']) {
			ClosedEmoji = <CDBIcon className='offlineCircle' fa icon="circle"/>;
			bottomText = <i><hr/>{Bots[Bot]['closed_for']}</i>;
		} else {
			bottomText = <Popup trigger={<button type="button" class="btn button btn-primary btn-lg btn-block">{t('learnMore')}</button>} modal>
				{
					getPopUp(Bot)
				}
			</Popup>;
		}
		return showTheseBots.push(
			<div id={key} class="bot">
				<img src={photo} alt={t('botLogo')} loading="lazy"/>
				<h2>{ClosedEmoji} {Bot}</h2>
				<p>
					{Bots[Bot]['description']}
					<br/>
					{bottomText}
				</p>
			</div>
		);
	});
	
	return (<div className="myBots">{showTheseBots}</div>);
}

export default ShowBotList;