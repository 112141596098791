import React from 'react';
import './about.css';
import { useTranslation } from "react-i18next";
import Footer from '../footer';

const About = () => {
	const { t } = useTranslation();
	var hostname = 'Hetzner';
	
	return (
		<div id="about">
			<header className="header">
				<h1>{t('aboutTitle')}</h1>
				{t('aboutDescription')}
			</header>
			<div className="aboutContainer">
				<p className="mySelf">
					<p>
						<h1>{t('whoAmI')}</h1>
						{t('whoAmIDescription')}
					</p>
					<p>
						<h1>{t('whatInspiredMe')}</h1>
						{t('whatInspiredMeDescription')}
					</p>
					<p>
						<h1>{t('whatAreMyAmbitions')}</h1>
						{t('whatAreMyAmbitionsDescription')}
					</p>
				</p>
				<p className="mySelf">
					<p>
						<h1>{t('whatExpHaveYouHad')}</h1>
						{t('whatExpHaveYouHadDescription').replace('[HOSTNAME]', hostname)}
					</p>
					<p>
						<h1>{t('whatExpHaveYouHad2')}</h1>
						{t('whatExpHaveYouHad2Description')}
					</p>
					
				</p>
			</div>
			<div className="footerSpace">
				<Footer />
			</div>
		</div>
	);
}

export default About;