import React from 'react';
import './contact.css';
import { useTranslation } from "react-i18next";
import { CDBBox, CDBIcon } from 'cdbreact';
import Footer from '../footer';

const Contact = () => {
	const { t } = useTranslation();
	const notAvailable = <i>{t('notAvailable')}</i>;
	const contacts = {
		phone: notAvailable,
		mail: notAvailable,
		assistant: notAvailable,
		telegram:(<a href="https://t.me/NeleBotsSupportBot">@NeleBotsSupportBot</a>)
	};
	
	if (contacts.phone !== notAvailable) {
		var Phone = <CDBBox className="contactBox">
			<CDBIcon fas icon="phone" size="6x" />
			{t('contactPhone')}: {contacts.phone}
		</CDBBox>
	}
	if (contacts.mail !== notAvailable) {
		var Mail = <CDBBox className="contactBox">
			<CDBIcon fas icon="envelope" size="6x" />
			{t('contactMail')}: {contacts.mail}
		</CDBBox>;
	}
	if (contacts.assistant !== notAvailable) {
		var Assistant = <CDBBox className="contactBox">
			<CDBIcon fas icon="robot" size="6x" />
			{t('contactAssistant')}: {contacts.assistant}
		</CDBBox>
	}
	if (contacts.telegram !== notAvailable) {
		var Telegram = <CDBBox className="contactBox">
			<CDBIcon fab icon="telegram" size="6x" />
			{t('contactTelegram')}: {contacts.telegram}
		</CDBBox>;
	}
	
	return (
		<div id="contact">
			<header className="header">
				<h1>{t('contactTitle')}</h1>
				<div className="contactMethods">
					{Phone}
					{Mail}
					{Assistant}
					{Telegram}
				</div>
			</header>
			<div className="footerSpace">
				<Footer />
			</div>
		</div>
	);
}

export default Contact;