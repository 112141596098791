import React from 'react';
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import NavDropdown from 'react-bootstrap/NavDropdown';

function LanguageSwitch() {
	const { t } = useTranslation();

	function handleLanguageChange(event) {
		document.cookie = 'locale=' + event + '; max-age=31536000; path=/';
		i18n.changeLanguage(event);
	}

	return (
		<NavDropdown title={t('Language') +  ": " + t('LanguageName')} id="collasible-nav-dropdown">
			<div onClick={(event) => handleLanguageChange("en")}><NavDropdown.Item>English</NavDropdown.Item></div>
			<div onClick={(event) => handleLanguageChange("it")}><NavDropdown.Item>Italiano</NavDropdown.Item></div>
			<div onClick={(event) => handleLanguageChange("fr")}><NavDropdown.Item>Français</NavDropdown.Item></div>
			<NavDropdown.Divider />
			<NavDropdown.Item href="https://translations.nelebots.com">
				{t('LocalizationPlatform')}
			</NavDropdown.Item>
		</NavDropdown>
	);
}

export default LanguageSwitch;