import React from "react";
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './App.css';
import CollapsibleNavbar from './Navbar';
import Home from './pages/home';
import Bots from './pages/bots';
import Stats from './pages/stats';
import Contact from './pages/contact';
import About from './pages/about';
import NotFound from './pages/notFound';

function App() {
	return (
		<Router>
			<CollapsibleNavbar />
			<div className="App">
				<Routes>
					<Route exact path='/' element={<Home />} />
					<Route path='/bots' element={<Bots/>} />
					<Route path='/stats' element={<Stats/>} />
					<Route path='/contact' element={<Contact/>} />
					<Route path='/about' element={<About/>} />
					<Route path='*' element={<NotFound />}/>
				</Routes>
			</div>
		</Router>
	);
}

export default App;