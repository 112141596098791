import React from 'react';
import './bots.css';
import { useTranslation } from "react-i18next";
import Footer from '../footer';
import ShowBotList from '../myBots';

const Bots = () => {
	const { t } = useTranslation();	
	return (
		<div id="bots">
			<header className="header">
				<div className="mergeLogo">
					<img className="bottom-logo" src="assets/telegram-icon.png" alt={t('telegramLogo')}/>
					<img className="logo" src="assets/bots-icon.png" alt={t('botLogo')}/>
				</div>
				<h1>{t('botsTitle')}</h1>
				{t('botsDescription')}
			</header>
			<ShowBotList />
			<div className="footerSpace">
				<Footer />
			</div>
		</div>
	);
}

export default Bots;