import React from 'react';
import logo from './logo.svg';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import LanguageSwitch from './LanguageSwitch';
import { useTranslation } from "react-i18next";

function CollapsibleNavbar() {
	const { t } = useTranslation();
	
	return (
		<Navbar collapseOnSelect expand="lg" variant="dark">
		  <Container>
			<Navbar.Brand href="/"> <img
				  alt=""
				  src={logo}
				  width="30"
				  height="30"
				  className="d-inline-block align-top"
				/>{' '}
				{t('navBarTitle')}</Navbar.Brand>
			<Navbar.Toggle aria-controls="responsive-navbar-nav" />
			<Navbar.Collapse id="responsive-navbar-nav">
			  <Nav className="me-auto">
				<Nav.Link href="/bots">{t('navBarBots')}</Nav.Link>
				<Nav.Link href="/about">{t('navBarAbout')}</Nav.Link>
				<Nav.Link href="/contact">{t('navBarContact')}</Nav.Link>
			  </Nav>
			  <Nav>
				<LanguageSwitch />
			  </Nav>
			</Navbar.Collapse>
		  </Container>
		</Navbar>
	);
}

export default CollapsibleNavbar;