import React from 'react';
import './stats.css';
import { useTranslation } from "react-i18next";
import Footer from '../footer';
import showBotCharts from '../myStats';
import Bots from '../data/bots.json';

const Stats = () => {
	const { t } = useTranslation();
	const query = new URLSearchParams(window.location.search);	
	var Bot = query.get('bot');
	var headerPhoto = <img className="logo" src="assets/bots-icon.png" alt={t('botLogo')}/>;
	var BotDescription = '';
	var Stats = <div className="alert alert-danger error" role="alert">
		{t('botNotFound')}
	</div>;
	if (Bots[Bot]) {
		if (Bots[Bot]['photo']) {
			headerPhoto = <img className="bot-logo" src={"assets/bots/" + (Bots[Bot]['photo'])} alt={t('botLogo')}/>;
		}
		BotDescription = t('botStatsDescription').replace('[BOT_NAME]', Bot);
		Stats = <div id="chartCollection" className="chartCollection">
			<div id="chart1"/>
			<div id="chart2"/>
			<div id="chart3"/>
			<div id="chart4"/>
		</div>;
		showBotCharts(Bot, Bots[Bot]);
	} else {
		Bot = "Error 404: Bot Not found";
	}
	
	return (
		<div id="stats">
			<header className="header">
				<div className="mergeLogo">
					{headerPhoto}
				</div>
				<h1>{Bot}</h1>
				{BotDescription}
			</header>
			<div id="chartAlert"/>
			<div className="chartContainer">
				{Stats}
			</div>
			<div className="footerSpace">
				<Footer />
			</div>
		</div>
	);
}

export default Stats;