import React from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class PieChart extends React.Component {
	render() {
		return (
			<div id='PieChart'>
				<CanvasJSChart options = {this.props.options}/>
			</div>
		);
	}
}

export default PieChart;