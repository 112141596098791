import React from 'react';
import './default.css';
import { useTranslation } from "react-i18next";
import Footer from '../footer';

const NotFound = () => {
	const { t } = useTranslation();
	
	return (
		<div id='errorPage'>
			<header className="header">
				<p>
					<h1>{'{ ' + 404 + ' }'}</h1>
					{t('pageNotFound')}
				</p>
			</header>
			<div className="footerSpace">
				<Footer />
			</div>
		</div>
	);
}

export default NotFound;