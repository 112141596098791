import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './langs/en.json';
import itTranslation from './langs/it.json';
import frTranslation from './langs/fr.json';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();
const resources = {
  en: {
	title: "English",
	translation: enTranslation
  },
  it: {
	title: "Italiano",
	translation: itTranslation
  },
  fr: {
	title: "Français",
    translation: frTranslation
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: cookies.get('locale') || navigator.language.split("-")[0],
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;