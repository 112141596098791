import React from 'react';
import { useTranslation } from "react-i18next";
import Projects from './data/projects.json';

function showProjects() {
	const { t } = useTranslation();
	const showTheseProjects = [];
	
	Object.keys(Projects).map(function callback(Project, key) {
		var photo = "assets/bots/" + (Projects[Project]['photo']);
		if (Projects[Project]['closeDate']) {
			var NowDate = Projects[Project]['closeDate'];
			if (Projects[Project]['closedReason']) {
				var ClosedReason = <i>{Projects[Project]['closedReason']}</i>;
			}
		} else {
			NowDate = t('now');
			ClosedReason = "";
		}
		return showTheseProjects.push(
			<div id={key} className="project">
				<img src={photo} alt={t('botLogo')}/>
				<h2>{Project}</h2> 
				<i id="date">{Projects[Project]['bornDate']} <i className="fa fa-arrow-right" aria-hidden="true"></i> {NowDate}<br/></i>
				<p>{Projects[Project]['description']}</p>
				{ClosedReason}
			</div>
		);
	});
	
	return (<div className="myProjects">{showTheseProjects}</div>);
}

export default showProjects;