import React, { Component } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class AreaChart extends Component {
	render() {
		if (this.props.options) return (
			<div id='AreaChart'>
				<CanvasJSChart options = {this.props.options}/>
			</div>
		);
	}
}

export default AreaChart;
