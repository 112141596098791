import React from 'react';
import ReactDOM from 'react-dom/client';
import { useTranslation } from "react-i18next";
import { CDBIcon } from 'cdbreact';
import PieChart from "./chart/PieChart";
import AreaChart from "./chart/AreaChart";

function renderChartData (id, data, type) {
	var dataPoints = [];
	var options = '';
	
	if (type === 1) {
		var title = "Total users";
		var day = '';
		var month = '';
		var year = '';
		var d = new Date();
		var now = d.getTime();
		for (let i = 0; i < 31; i++) {
			var time = now + (1000 * 60 * 60 * 24 * i);
			d.setTime(time);
			year = d.getFullYear().toString();
			month = d.getMonth().toString();
			day = d.getDate().toString();
			if (month < 10) month = '0' + month;
			if (day < 10) day = '0' + day;
			if (
				data['users'] &&
				data['users'][year] &&
				data['users'][year][month] &&
				data['users'][year][month][day]) {
				dataPoints.push({
					x: (new Date(time)),
					y: parseInt(data['users'][year][month][day])
				});
			}
		}
		data = [{
			theme: "light2",
			animationEnabled: true,
			exportEnabled: true,
			title: {
				text: title,
				fontFamily: "tahoma",
				align: 'left'
			},
			type: "area",
			xValueFormatString: "MM/DD",
			axisY: {
				title: "Number of users",
				fontFamily: "tahoma",
				includeZero: false
			},
			dataPoints: dataPoints
		}];
		options = {
			animationEnabled: true,
			title: {
				text: title,
				fontFamily: "tahoma"
			},
			axisY: {
				title: "Number of users",
				fontFamily: "tahoma",
				includeZero: false,
			},
			data: data
		}
	} else if (type === 2) {
		title = "Users activity";
		day = '';
		month = '';
		year = '';
		d = new Date();
		now = d.getTime();
		for (let i = 0; i < 31; i++) {
			time = now + (1000 * 60 * 60 * 24 * i);
			d.setTime(time);
			year = d.getFullYear().toString();
			month = d.getMonth().toString();
			day = d.getDate().toString();
			if (month < 10) month = '0' + month;
			if (day < 10) day = '0' + day;
			if (
				data['users']['daily'] &&
				data['users']['daily'][year] &&
				data['users']['daily'][year][month] &&
				data['users']['daily'][year][month][day]) {
				dataPoints.push({
					x: (new Date(time)),
					y: parseInt(data['users']['daily'][year][month][day])
				});
			}
		}
		data = [{
			theme: "light2",
			animationEnabled: true,
			exportEnabled: true,
			title: {
				text: title,
				fontFamily: "tahoma",
				align: 'left'
			},
			type: "area",
			xValueFormatString: "MM/DD",
			axisY: {
				title: "Number of active users",
				fontFamily: "tahoma",
				includeZero: false
			},
			dataPoints: dataPoints
		}];
		options = {
			animationEnabled: true,
			title: {
				text: title,
				fontFamily: "tahoma"
			},
			axisY: {
				title: "Number of users",
				fontFamily: "tahoma",
				includeZero: false,
			},
			data: data
		}
	} else if (type === 3) {
		title = "New users";
		day = '';
		month = '';
		year = '';
		d = new Date();
		now = d.getTime();
		for (let i = 0; i < 31; i++) {
			time = now + (1000 * 60 * 60 * 24 * i);
			d.setTime(time);
			year = d.getFullYear().toString();
			month = d.getMonth().toString();
			day = d.getDate().toString();
			if (month < 10) month = '0' + month;
			if (day < 10) day = '0' + day;
			if (
				data['users']['new'] &&
				data['users']['new'][year] &&
				data['users']['new'][year][month] &&
				data['users']['new'][year][month][day]) {
				dataPoints.push({
					x: (new Date(time)),
					y: parseInt(data['users']['new'][year][month][day])
				});
			}
		}
		data = [{
			theme: "light2",
			animationEnabled: true,
			exportEnabled: true,
			title: {
				text: title,
				fontFamily: "tahoma",
				align: 'left'
			},
			type: "area",
			xValueFormatString: "MM/DD",
			axisY: {
				title: "Number of active users",
				fontFamily: "tahoma",
				includeZero: false
			},
			dataPoints: dataPoints
		}];
		options = {
			animationEnabled: true,
			title: {
				text: title,
				fontFamily: "tahoma"
			},
			axisY: {
				title: "Number of users",
				fontFamily: "tahoma",
				includeZero: false,
			},
			data: data
		}
	} else if  (type === 4) {
		title = "Spoken languages";
		Object.keys(data['langs']).map(function callback(Lang, key) {
			return dataPoints.push({ 
				y: data['langs'][Lang],
				label: Lang
			});
		});
		data = [{
			type: 'pie',
			startAngle: 270,
			toolTipContent: "<b>{label}</b>: {y}",
			showInLegend: "true",
			legendText: "{label}",
			indexLabelFontSize: 16,
			indexLabel: "{label} - {y}",
			dataPoints: dataPoints
		}];
		options = {
			animationEnabled: true,
			theme: 'light2',
			title: {
				text: title
			},
			data: data
		};
	}
	
	if (dataPoints.length === 0) return '';
	return options;
}

function showBotCharts (Bot, BotData) {
	const { t } = useTranslation();
	const delay = ms => new Promise(
		resolve => setTimeout(resolve, ms)
	);
	var id = BotData['clones'][0]['id'];
	var charts = 4;
	var chartDOMs = [];
	var result = '';
	
	document.addEventListener('DOMContentLoaded', async function(event) {
		var chartAlert = ReactDOM.createRoot(document.getElementById("chartAlert"));
		chartAlert.render(
			<div className="alert alert-info" role="alert">
				<CDBIcon fa icon="spinner" spin/> {t('botStatsProcessing')}
			</div>
		);
		await delay(2000).then(() => {
			chartAlert.render(
				<div className="alert alert-success" role="alert">
					<CDBIcon fa icon="check"/> {t('botStatsProcessedSuccessfully')}
				</div>
			);
			startHeandler(chartAlert);
		})
	});
	
	function startHeandler(chartAlert) {
		fetch("/assets/json/index.php?id=" + id)
		.then((res) => res.json())
		.then((data) => {
			for (var x of Array(charts).keys()) {
				x += 1;
				if (x <= 3) {
					const options = renderChartData(id, data, x);
					if (typeof options == "object") {
						if (typeof chartDOMs[x] === "undefined") {
							chartDOMs[x] = ReactDOM.createRoot(document.getElementById("chart" + x));
						}
						chartDOMs[x].render(<AreaChart options={options}/>);
					}
				} else if (x === 4) {
					const options = renderChartData(id, data, x);
					if (typeof options == "object") {
						if (typeof chartDOMs[x] === "undefined") {
							chartDOMs[x] = ReactDOM.createRoot(document.getElementById("chart" + x));
						}
						chartDOMs[x].render(<PieChart options={options}/>);
					}
				}
			}
		})
		.catch((err) => {
			chartAlert.render(
				<div className="alert alert-danger error" role="alert">
					{t('errorLoadingStats')}
				</div>
			);
			return false;
		})
		return true;
	}
	
	return (
		<div className="chartCollection">
			{result}
		</div>
	);
}

export default showBotCharts;